import './index.scss'

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import ReactGA from 'react-ga'
import Icon from '../Icon'
import Button from '../Button'

import scrollTo from '../../helpers/scroll-to'
import getPosition from '../../helpers/get-position'

const bem = new BEMHelper('from-to')

const KEY_ENTER = 13
const KEY_UP = 38
const KEY_DOWN = 40

export default class FromTo extends PureComponent {
  static propTypes = {
    ports: PropTypes.array.isRequired,
    from: PropTypes.string,
    to: PropTypes.string,
    placeholder: PropTypes.string,
    noEmptyError: PropTypes.bool,
    autoFocus: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    postal: PropTypes.bool,
    postalCodes: PropTypes.array,
    countryList: PropTypes.array,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: () => {},
    onChange: () => {},
    ports: [],
    postalCodes: [],
    from: '',
    to: '',
    countryList: [
      { value: 'NO', name: 'Norway (NO)', length: 4 },
      { value: 'DE', name: 'Germany (DE)', length: 5 },
      { value: 'DN', name: 'Denmark (DN)', length: 4 },
      { value: 'AP', name: 'Netherlands (AP)', length: 4 },
    ],
  }

  constructor(props) {
    super(props)

    this.state = {
      activeSuggestion: 0,
      suggestions: [],
      active: null,
      input: { from: props.from, to: props.to },
    }

    this.inputRefs = {}
    this.blurDelay = null
  }

  debounce = null

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps({ from, to, postalCodes }) {
    const { input } = this.state
    if (
      (from !== input.from && from !== this.props.from) ||
      (to !== input.to && to !== this.props.to)
    ) {
      this.setState(
        {
          input: {
            from: from !== input.from ? from : input.from,
            to: to !== input.to ? to : input.to,
          },
        },
        this.handleSubmit,
      )
    }

    if (postalCodes.length !== this.props.postalCodes.length) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(this.forceUpdate, 100)
    }
  }

  componentDidMount() {
    const { autoFocus } = this.props
    if (autoFocus && this.inputRefs.from) {
      setTimeout(
        () => {
          this.inputRefs.from.focus()
        },
        isNaN(autoFocus) ? 0 : autoFocus,
      )
    }
  }

  forceUpdate = () => {
    const { active, input } = this.state
    const event = { target: { value: input[active] } }

    this.handleChange(active)(event)
  }

  handleSubmit = event => {
    if (event) {
      event.preventDefault()
    }

    if (this.checkIfCorrect()) {
      this.props.onSubmit({ ...this.state.input })

      const to = getPosition(this.form).y - 10

      scrollTo({ to, duration: 500 })
    }
  }

  checkIfCorrect = () => {
    const { input } = this.state

    if (!input || !input.from || !input.to) {
      return false
    }

    const fromMatch = this.mergedList().some(
      ({ name }) => (name || '').toLowerCase() === input.from.toLowerCase(),
    )
    const toMatch = this.mergedList().some(
      ({ name }) => (name || '').toLowerCase() === input.to.toLowerCase(),
    )

    const fromIsValid = fromMatch
    const toIsValid = toMatch

    return fromIsValid && toIsValid
  }

  mergedList = () => {
    const { ports, postalCodes } = this.props

    return [
      ...ports.map(item => ({
        ...item,
        type: 'port',
      })),
      ...postalCodes.map(item => ({
        ...item,
        type: 'postal',
      })),
    ]
  }

  filterSuggestion = (value, key) => suggestion => {
    const { from, to } = this.state.input

    if (!suggestion) {
      return false
    }

    if (key === 'from' && to === suggestion.name) {
      return false
    }

    if (key === 'to' && from === suggestion.name) {
      return false
    }

    return (
      Object.values(suggestion)
        .toString()
        .split(',')
        .join('')
        .toLowerCase()
        .indexOf((value || '').toLowerCase()) > -1
    )
  }

  handleChange = key => ({ target: { value } }) => {
    const suggestions = this.mergedList().filter(
      this.filterSuggestion(value, key),
    )

    clearTimeout(this.blurDelay)

    this.setState(
      {
        activeSuggestion: 0,
        suggestions,
        active: key,
        input: { ...this.state.input, [key]: value },
      },
      () => {
        this.handleSubmit()
        this.props.onChange(key, value)
      },
    )
  }

  handleFocus = key => event => {
    const {
      target: { value = '' },
    } = event || {}

    if (event && event.target) {
      event.target.select()
    }

    const suggestions = this.mergedList().filter(
      this.filterSuggestion(value, key),
    )
    clearTimeout(this.blurDelay)

    this.setState({
      activeSuggestion: 0,
      suggestions,
      active: key,
    })
    // Log use in Google Analytics
    ReactGA.event({
      category: 'Scheduling Search Autocomplete',
      action: 'Input focus',
      value: this.state.input[key],
    })
  }

  handleKeyDown = key => ({ keyCode }) => {
    const { activeSuggestion, suggestions } = this.state

    if (keyCode === KEY_ENTER) {
      // Log use in Google Analytics
      ReactGA.event({
        category: 'Scheduling Search Autocomplete',
        action: 'Select port by enter',
        value: this.state.input[key],
      })
      this.setState(
        {
          activeSuggestion: 0,
          active: null,
          input: {
            ...this.state.input,
            [key]: (suggestions[activeSuggestion] || {}).name,
          },
        },
        this.handleSubmit,
      )
    } else if (keyCode === KEY_UP) {
      if (activeSuggestion !== 0) {
        this.setState(
          { activeSuggestion: activeSuggestion - 1 },
          this.scrollToActive,
        )
      }
    } else if (keyCode === KEY_DOWN) {
      if (activeSuggestion !== suggestions.length - 1) {
        this.setState(
          { activeSuggestion: activeSuggestion + 1 },
          this.scrollToActive,
        )
      }
    }
  }

  scrollToActive = () => {
    if (!this.activeItem) {
      return
    }

    const top = this.activeItem.offsetTop
    const height = this.activeItem.offsetHeight
    const bottom = top + height
    const listHeight = this.suggestionList.offsetHeight
    const scrollPos = this.suggestionList.scrollTop
    const visible = listHeight + scrollPos
    const modifier = 39

    if (bottom + modifier > visible) {
      this.suggestionList.scrollTop = bottom - listHeight + modifier
    }

    if (top - modifier < scrollPos) {
      this.suggestionList.scrollTop = top - modifier
    }
  }

  handleClick = (key, index) => () => {
    const { suggestions } = this.state

    this.setState(
      {
        activeSuggestion: 0,
        input: { ...this.state.input, [key]: suggestions[index].name },
      },
      this.handleSubmit,
    )

    this.handleBlur()

    // Log use in Google Analytics
    ReactGA.event({
      category: 'Scheduling Search Autocomplete',
      action: 'Select port by click',
      value: this.state.input[key],
    })
  }

  handleBlur = () => {
    this.blurDelay = setTimeout(() => {
      this.setState({
        active: null,
      })
    }, 120)
  }

  handleFlip = () => {
    const { input } = this.state

    // Log use in Google Analytics
    ReactGA.event({
      category: 'Scheduling Search Autocomplete',
      action: 'Flip from to',
    })

    this.setState(
      {
        input: { from: input.to, to: input.from },
      },
      this.handleSubmit,
    )
  }

  handleClear = key => () => {
    const { input } = this.state

    this.setState({
      input: {
        ...input,
        [key]: '',
      },
    })
  }

  renderItem = key => ({ name, code, country, type, index, id }) => {
    const { activeSuggestion } = this.state
    const active = activeSuggestion === index

    return (
      <button
        type="button"
        key={id}
        {...bem('suggestion', {
          active,
        })}
        onMouseDown={this.handleClick(key, index)}
        tabIndex="-1"
        ref={ref => {
          if (active) {
            this.activeItem = ref
          }
        }}
      >
        <strong {...bem('port')}>{name}</strong>
        {country && (
          <span {...bem('country')}>
            {country}
            {type === 'postal' && <em> ({code})</em>}
          </span>
        )}
      </button>
    )
  }

  renderNoResults = (suggestions, key) => {
    const { noEmptyError, postal } = this.props
    const { input } = this.state
    if (suggestions.length === 0 && !noEmptyError) {
      // Log use in Google Analytics
      ReactGA.event({
        category: 'Scheduling Search Autocomplete',
        action: 'No port or place found',
        value: input[key],
      })
      return (
        <>
          <strong {...bem('suggestion-header')}>
            {postal ? 'No port or place found' : 'No ports found'}
          </strong>
          <div {...bem('suggestion', 'empty')}>
            <p>Please contact us and we will find a route for your cargo.</p>
            <Button tertiary block to="/about/contact/">
              Contact us
            </Button>
          </div>
        </>
      )
    }

    return null
  }

  renderPostalHelp = key => {
    const { postal } = this.props
    const { input } = this.state

    if ((!input[key] || input[key] === '') && postal) {
      return (
        <>
          <strong {...bem('suggestion-header')}>Places</strong>
          <div {...bem('suggestion', 'empty')}>
            You can search for any address or place.
          </div>
        </>
      )
    }

    return null
  }

  renderSuggestions = key => {
    const { active } = this.state
    const suggestions = this.state.suggestions.map((item, index) => ({
      ...item,
      index,
    }))

    const ports = suggestions.filter(({ type }) => type === 'port')
    const postals = suggestions.filter(({ type }) => type === 'postal')

    if (active === key) {
      return (
        <nav
          {...bem('suggestions')}
          ref={ref => {
            this.suggestionList = ref
          }}
        >
          {this.renderNoResults(suggestions, key)}
          {this.renderPostalHelp(key)}
          <div {...bem('suggestions-wrapper')}>
            {ports.length > 0 && (
              <strong {...bem('suggestion-header')}>
                Ports ({ports.length})
              </strong>
            )}
            {ports.map(this.renderItem(key))}
          </div>
          {postals.length > 0 && (
            <strong {...bem('suggestion-header')}>
              Places ({postals.length})
            </strong>
          )}
          {postals.map(this.renderItem(key))}
        </nav>
      )
    }

    return null
  }

  renderGroup = key => {
    const input = this.state.input[key]

    const placeholder =
      this.props.placeholder ||
      (key === 'from' ? 'Port or place' : 'Place or port')

    const { type } =
      this.mergedList().find(
        ({ name }) =>
          (name || '').toLowerCase() === (input || '').toLowerCase(),
      ) || {}

    return (
      <div {...bem('group', key)}>
        <span {...bem('label')}>
          <label htmlFor={`from-to-input-${key}`}>
            {key}
            {type === 'port' && ' port'}
            {type === 'postal' && ' door'}
          </label>
        </span>

        <input
          {...bem('input', { [key]: key })}
          id={`from-to-input-${key}`}
          type="text"
          onChange={this.handleChange(key)}
          onFocus={this.handleFocus(key)}
          onKeyDown={this.handleKeyDown(key)}
          value={input}
          placeholder={placeholder}
          autoComplete="off"
          ref={ref => {
            this.inputRefs[key] = ref
          }}
        />
        {input && (
          <button
            type="button"
            {...bem('clear')}
            onClick={this.handleClear(key)}
            tabIndex="-1"
            aria-label="Clear"
          >
            <Icon icon="x" />
          </button>
        )}
        {this.renderSuggestions(key)}
      </div>
    )
  }

  render() {
    const { active } = this.state

    return (
      <>
        <form
          {...bem('', { active })}
          onSubmit={this.handleSubmit}
          ref={ref => {
            this.form = ref
          }}
        >
          <div {...bem('wrapper')}>
            {this.renderGroup('from')}
            <button
              type="button"
              {...bem('flip')}
              onClick={this.handleFlip}
              tabIndex="-1"
              aria-label="Flip from and to"
            >
              <Icon icon="flip" />
            </button>
            {this.renderGroup('to')}
          </div>
        </form>

        {active && (
          <button
            type="button"
            aria-label="Close suggestions"
            {...bem('backdrop')}
            onClick={this.handleBlur}
            onFocus={this.handleBlur}
          />
        )}
      </>
    )
  }
}
