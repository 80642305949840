import axios from 'axios'

import config from '../../config.json'
const API_SOURCE = `${config.backend_server_url}_api/schedule/v2/`

export default async function getPortsV2 () {
  try {
    const response = await axios.get(`${API_SOURCE}ports/?format=json`)

    const ports = response.data.map(
      ({ id, name, country = null, code: portCode = null }) => ({
        id,
        name,
        country,
        code: portCode,
      }),
    )

    return ports
  } catch (error) {
    console.error(error)
    return []
  }
}
